
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Mutual } from "@/core/model/mutual";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";

export default defineComponent({
  name: "admin-mutual-info",
  emit: ["mutual-status-changed"],
  components: {
    //Dropdown,
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const store = useStore();
    const route = useRoute();
    const mutual = ref<Mutual>({} as Mutual);

    onMounted(() => {
      loadData();
    });

    const loadData = () => {
      loading.value = true;
      store.dispatch(Actions.ADMIN_MUTUAL_GET, route.params.uuid).then(() => {
        mutual.value = store.state.AdminMutualesModule.mutual;
        loading.value = false;
      });
    };

    const confirmChangeStatusMutual = () => {
      Swal.fire({
        text:
          "¿Confirma que desea " +
          (mutual.value.active ? "desactivar" : "activar") +
          " esta mutual?",
        icon: "question",
        buttonsStyling: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          changeStatusMutual();
        }
      });
    };

    const changeStatusMutual = () => {
      store
        .dispatch(Actions.ADMIN_MUTUAL_CHANGE_STATUS, {
          mutual: route.params.uuid,
          active: !mutual.value.active,
        })
        .then(() => {
          if (store.state.AdminMutualesModule.success) {
            emit("mutual-status-changed");
            ToastService.show("Estado actualizado con éxito", "success");
            loadData();
          }
        })
        .catch((error) => {
          displayErrors(store, "AdminMutualesModule");
        });
    };

    return {
      mutual,
      loading,
      confirmChangeStatusMutual,
    };
  },
});
